import React, { Component } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Slider from "react-slick";

class Work_Cafes extends Component {
  constructor() {
    super();
    this.state = {
      banner_img: [],
      base_url: global.BaseUrl,
      assets_url: global.AssetsUrl,
    };
  }

  componentDidMount() {
    fetch(`${this.state.base_url}/getBannerById/11`).then((resp) => {
      resp.json().then((result) => {
        console.log(result);
        this.setState({ banner_img: result.banner_images });
        // this.setState({ headingType: result.data});
      });
    });
  }

  render() {
    const { banner_img } = this.state;

    var settings = {
      className: "",
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      cssEase: "linear",
      adaptiveHeight: true,
    };

    return (
      <>
        <div className="container-fluid slider_top">
          <div className="row">
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/work_one.jpg"
                alt=""
                className="bnr_img_30 padding_set"
              />
            </div>
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/work_two.jpg"
                alt=""
                className="bnr_img_30 padding_set mobile_hide"
              />
            </div>
            <div className="col-md-4 col-12">
              <img
                src="./assets/images/banner/work_three.jpg"
                alt=""
                className="bnr_img_30 padding_set mobile_hide"
              />
            </div>
          </div>
        </div>
        <section className="section-reservation-form padding-bottom-100">
          <div className="container">
            <div className="swin-sc swin-sc-title style-2">
              <h3 class="title wc-title">
                <span>WORK CAFES</span>
              </h3>
              <p className="cafe-text text_justify">
                Work cafés have been aligned with new global standards embracing
                design elements and collaborative seating to create a unique
                space for informal meetings and gatherings
              </p>
            </div>
            <div className="reservation-form">
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <h4 className="service-title text-center">
                    What Do Work Cafes Offer:
                  </h4>
                  <p className="cafe-text text-center">
                    Our amenities aim to provide the best employee experience
                    across our cafes and pantry areas
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="box-shadow">
                    <div className="work_sustain">
                      <img
                        src="./assets/images/icon/Beverage-machine.png"
                        alt="Beverage Machine"
                      />
                      <p className="sustain_new">Beverage Machines</p>
                    </div>
                    <div>
                      <p className="sustain_new_para">
                        Our beverage machines offer a wide variety of both hot
                        and cold drinks free for employees to enjoy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="box-shadow">
                    <div className="work_sustain">
                      <img
                        src="./assets/images/icon/filter-water.png"
                        alt="Filtered Water"
                      />
                      <p className="sustain_new">Filtered Water</p>
                    </div>
                    <div>
                      <p className="sustain_new_para">
                        Clean, filtered water is available hot or cold.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="box-shadow">
                    <div className="work_sustain">
                      <img
                        src="./assets/images/icon/microwave.png"
                        alt="Microwave Oven"
                      />
                      <p className="sustain_new">Microwave Oven</p>
                    </div>
                    <div>
                      <p className="sustain_new_para">
                        Provided for reheating home-cooked meals using
                        microwave-safe containers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="box-shadow">
                    <div className="work_sustain">
                      <img
                        src="./assets/images/icon/fridge.png"
                        alt="Chillers"
                      />
                      <p className="sustain_new">Chillers</p>
                    </div>
                    <div>
                      <p className="sustain_new_para">
                        For home-brought food and drinks, chillers are available
                        for safe storage.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="box-shadow">
                    <div className="work_sustain">
                      <img
                        src="./assets/images/icon/vending-machine.png"
                        alt="Snack Spot"
                      />
                      <p className="sustain_new">Snack Spot</p>
                    </div>
                    <div>
                      <p className="sustain_new_para">
                        Stacked with a full selection of our favorite retail
                        items available in our grab-scan-go shelves.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swin-sc swin-sc-contact-form light mtl"></div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Work_Cafes;
