import React from "react";

const Disclaimer = () => {
  return (
    <div
      className="container mt-5 "
      style={{ padding: "40px 0", color: "#111" }}
    >
      <section className="section-reservation-form">
        <div className="container">
          <div className="swin-sc swin-sc-title style-2">
            <h3 className="title swin-sc-title">
              <span>Disclaimer</span>
            </h3>
          </div>
        </div>
      </section>
      <section className="row p-4">
        <div className="para">
          <div
            className="tab2_tab"
            style={{ width: "90%", justifyContent: "center" }}
          >
            <p
              style={{
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              }}
            >
              <ul style={{ marginBottom: 0, paddingInlineStart: "48px" }}>
                <li
                  style={{ listStyleType: "disc", verticalAlign: "baseline" }}
                >
                  <p style={{ marginTop: 0, marginBottom: 0, lineHeight: 1.2 }}>
                    <span style={{ whiteSpace: "pre-wrap" }}>
                      The photos used in this webpage are for representation
                      purposes only.
                    </span>
                  </p>
                </li>
                <li
                  style={{ listStyleType: "disc", verticalAlign: "baseline" }}
                >
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    A third-party expert conducts periodic hygiene inspections
                    and food sample collections in our in-house kitchen.
                  </span>
                </li>
                <li
                  style={{ listStyleType: "disc", verticalAlign: "baseline" }}
                >
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    The food products produced in our kitchen may have come in
                    contact with most common allergens. Employees having food
                    allergies are advised to use their discretion for
                    consumption. If allergic reactions are known to be severe,
                    employees are advised to consume home-cooked food only.
                  </span>
                </li>
                <li
                  style={{ listStyleType: "disc", verticalAlign: "baseline" }}
                >
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    Catering food orders should be placed 7 working days ahead
                    of the event. Cancellations may be subject to penalties.
                  </span>
                </li>
                <li
                  style={{ listStyleType: "disc", verticalAlign: "baseline" }}
                >
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    On weekends, scaled down food programs are available. For
                    more details, refer to our outlets' operating hours or
                    contact our Café Manager.
                  </span>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Disclaimer;
