import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="" style={{ background: "#737070" }}>
            <div className="container">
              <div className="ft-widget-area footer_main_div">
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <div className="ft-area1">
                      <div className="swin-widget swin-widget-about">
                        <div className="clearfix">
                          <div className="header-logo">
                            <img
                              src="./assets/images/logo/footer_logo.png"
                              alt="avenue"
                              className="logo-img site_logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <div className="ft-area1">
                          <div className="swin-widget widget-about">
                            <div className="title-widget footer_heading">
                              Lobby Reception
                            </div>
                            <div className="about-contact-info clearfix">
                              <div className="info-content phone-content">
                                <div className="info-icon">
                                  <i className="fa fa-phone"></i>
                                </div>
                                <div className="info-text">
                                  <p>+632 7756 3691 / ext. 63691</p>
                                </div>
                              </div>
                              <div className="info-content email-content">
                                <div className="info-icon">
                                  <i className="fa fa-envelope"></i>
                                </div>
                                <div className="info-text">
                                  <p>lobbyreception.ph@jpmchase.com</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="ft-area1">
                          <div className="swin-widget widget-about">
                            <div className="title-widget footer_heading">
                              Client and Conference Center
                            </div>
                            <div className="about-contact-info clearfix">
                              <div className="info-content phone-content">
                                <div className="info-icon">
                                  <i className="fa fa-phone"></i>
                                </div>
                                <div className="info-text">
                                  <p>+632 7756 3649 / ext. 63649</p>
                                </div>
                              </div>
                              <div className="info-content email-content">
                                <div className="info-icon">
                                  <i className="fa fa-envelope"></i>
                                </div>
                                <div className="info-text">
                                  <p>
                                    clientandconferencecenter.ph@jpmchase.com
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <div className="ft-area1">
                          <div className="swin-widget widget-about">
                            <div className="title-widget footer_heading">
                              Employee Dining
                            </div>
                            <div className="about-contact-info clearfix">
                              <div className="info-content phone-content">
                                <div className="info-icon">
                                  <i className="fa fa-phone"></i>
                                </div>
                                <div className="info-text">
                                  <p>+632 7756 3693 / ext. 63693</p>
                                </div>
                              </div>
                              <div className="info-content email-content">
                                <div className="info-icon">
                                  <i className="fa fa-envelope"></i>
                                </div>
                                <div className="info-text">
                                  <p>employeedining.ph@jpmchase.com</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="ft-area1">
                          <div className="swin-widget widget-about">
                            <div className="title-widget footer_heading">
                              Pantry Services
                            </div>
                            <div className="about-contact-info clearfix">
                              <div className="info-content phone-content">
                                <div className="info-icon">
                                  <i className="fa fa-phone"></i>
                                </div>
                                <div className="info-text">
                                  <p>+632 7756 3692 / ext. 63692</p>
                                </div>
                              </div>
                              <div className="info-content email-content">
                                <div className="info-icon">
                                  <i className="fa fa-envelope"></i>
                                </div>
                                <div className="info-text">
                                  <p>employeedining.ph@jpmchase.com</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
